import { ReactNode } from "react";
import cx from "classnames";
import { ReactComponent as ProjectIcon } from "../../../assets/svg/pm.svg";
import styles from "../styles.module.scss";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
interface IProps {
  url: string;
  isDragging: boolean;
  icon?: ReactNode;
  title: string;
  img?: string;
  subTitle: string;
  backgroundColor?: string;
  disabled: boolean;
}

const DEFAULT_ICON = <ProjectIcon height={80} width={80} />;

export default function Widget({
  url,
  isDragging = false,
  icon = DEFAULT_ICON,
  img = "",
  title = "Title",
  subTitle = "subTitle",
  backgroundColor = "#1973bb",
  disabled = false,
}: IProps) {
  const _handleClick = (e: any) => {
    //if (isDragging && !disabled) {
    global.location.replace(url);
    //}
  };

  const classes = cx({
    [styles.boxItem]: true,
    [styles.grow]: !disabled,
    [styles.disabled]: disabled,
  });

  const theme = useTheme();

  return (
    <Card onClick={_handleClick}>
      <CardActionArea>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {subTitle}
          </Typography>
        </CardContent>

        <CardMedia
          component="img"
          image={img}
          alt="Live from space album cover"
        />
      </CardActionArea>
    </Card>
  );
}
