import { useState } from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../context/auth";
import axios from "axios";

import styles from "./styles.module.scss";
import SignIn from "./login2";

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();

  const referer = props.location?.state?.referer || "/";

  function toFormData(data) {
    const tmp = new FormData();
    Object.keys(data).forEach((key) => {
      tmp.append(key, data[key]);
    });
    return tmp;
  }

  function postLogin(e) {
    // e.preventDefault();
    axios
      .post("https://apigenisys.dnsfor.me/auth/login.php", toFormData(e))
      .then((result) => {
        if (result.status === 200) {
          setAuthTokens(result.data);
          setLoggedIn(true);
          // setAuthTokens(result.data);
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      });
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return <SignIn postLogin={postLogin} isError={isError} />;
}

export default Login;
