import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import jwt_decode from "jwt-decode";
import { AuthContext } from "./context/auth";
import Login from "./pages/Login";
import Applauncher from "./pages/AppLauncher";

function App(props) {
  const existingTokens =
    localStorage.getItem("@auth.token") !== ""
      ? localStorage.getItem("@auth.token")
      : "";

  let _authTokens = {};
  try {
    _authTokens = jwt_decode(JSON.parse(existingTokens));
  } catch (error) {
    //
  }
  const [authTokens, setAuthTokens] = useState(_authTokens);

  const setTokens = (data) => {
    localStorage.setItem("@auth.userdata", JSON.stringify(data));
    localStorage.setItem("@auth.token", JSON.stringify(data?.token));

    try {
      setAuthTokens(jwt_decode(data?.token));
    } catch (error) {
      //
    }
    // console.log(jwt_decode(data?.token));
  };

  useEffect(() => {
    //setAuthTokens(JSON.parse(window.localStorage.getItem("@auth.userdata") || "{}"));
  }, []);
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Route exact path="/">
          <Redirect to="/admin" />
        </Route>
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={Signup} /> */}
        <PrivateRoute path="/admin" component={Applauncher} />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
